import { assign } from 'xstate'
import {
  fetchLogOut,
  fetchReports,
  initSingleScan,
  checkSingleScan,
} from './actions/index'

export const authState = {
  initial: 'displayUI',
  onDone: { target: 'notAuth' },
  states: {
    displayUI: {
      on: {
        LOGOUT: [
          {
            target: 'loggingOut',
          },
        ],
        FETCH_REPORTS: [
          {
            target: 'fetchReports',
          },
        ],
        INIT_SINGLE_SCAN: [
          {
            target: 'singleScan',
          },
        ],
      },
    },
    fetchReports: {
      initial: 'performingRequest',
      onDone: { target: 'displayUI' },
      states: {
        performingRequest: {
          invoke: {
            id: 'fetchReports',
            src: async (ctx, event) => fetchReports(ctx, event),
            onDone: {
              target: 'successRequest',
              actions: assign({
                reports: (ctx, event) => event.data,
              }),
            },
            onError: {
              target: 'failedRequest',
            },
          },
        },
        failedRequest: { type: 'final' },
        successRequest: { type: 'final' },
      },
    },
    singleScan: {
      initial: 'performingSingleScan',
      onDone: { target: 'displayUI' },
      states: {
        performingSingleScan: {
          invoke: {
            id: 'initSingleScan',
            src: async (ctx, event) => initSingleScan(ctx, event),
            onDone: {
              target: 'singleScanProgress',
              actions: assign({
                scanInfo: (ctx, event) => event.data,
              }),
            },
            onError: {
              target: 'failedSingleScan',
            },
          },
        },

        singleScanProgress: {
          invoke: {
            id: 'checkSingleScanProgress',
            src: async (ctx, event) => checkSingleScan(ctx, event),
            onDone: {
              target: 'successSingleScan',
              actions: assign({
                scanData: (ctx, event) => event.data,
              }),
            },
          },
        },

        failedSingleScan: { type: 'final' },

        successSingleScan: { type: 'final' },
      },
    },

    loggingOut: {
      invoke: {
        id: 'doLogout',
        src: async (ctx, event) => fetchLogOut(ctx, event),
        onDone: {
          target: 'loggedOut',
          actions: assign({
            userInfo: () => {},
          }),
        },
        onError: {
          target: 'displayUI',
        },
      },
    },
    loggedOut: { type: 'final' },
  },
}
