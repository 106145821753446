import React from 'react'
import { useMachine } from '@xstate/react'
import { createMachine } from './stateMachine'

import Context from './context'

const Provider = props => {
  const stateMachine = createMachine()
  const [machine, send] = useMachine(stateMachine, {
    devTools: true,
  })

  return (
    <Context.Provider
      value={{
        machine,
        send,
      }}
    >
      {props.children}
    </Context.Provider>
  )
}

export default Provider
