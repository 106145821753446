import React from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'
import { Analytics } from 'aws-amplify'
import Provider from './state/provider'
import InitAmplify from './config/amplifyInit'
import './App.scss'
import './index.css'

const Auth = React.lazy(() => import('./components/auth/root'))
const Dashboard = React.lazy(() =>
  import('./components/dashboard/DefaultLayout')
)
const Root = React.lazy(() => import('./components/root'))

InitAmplify()

Analytics.configure({ disabled: true })

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
)

function App() {
  return (
    <React.Fragment>
      <Provider>
        <HashRouter>
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route
                exact
                path="/auth"
                name="Login Page"
                render={props => <Auth {...props} />}
              />
              <Route
                path="/dashboard"
                name="Dashboard"
                render={props => <Dashboard {...props} />}
              />
              <Route
                path="/"
                name="Root"
                render={props => <Root {...props} />}
              />
            </Switch>
          </React.Suspense>
        </HashRouter>
      </Provider>
    </React.Fragment>
  )
}

export default App
