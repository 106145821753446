export const stage = process.env.REACT_APP_STAGE || 'dev'

export const config = {
  dev: {
    cognito: {
      REGION: 'us-east-2',
      USER_POOL_ID: 'us-east-2_j4rQhn6xP',
      APP_CLIENT_ID: '35q3f3pfaio2i23ua8n2e7elft',
      IDENTITY_POOL_ID: 'us-east-2:580ed80a-f742-42d8-bcc6-fd96c851fc1c',
    },
    apiGateway: {
      REGION: 'us-east-2',
      URL: 'https://xok68dbsg5.execute-api.us-east-2.amazonaws.com/dev',
    },
  },
  prod: {
    cognito: {
      REGION: 'us-east-2',
      USER_POOL_ID: 'us-east-2_j4rQhn6xP',
      APP_CLIENT_ID: '35q3f3pfaio2i23ua8n2e7elft',
      IDENTITY_POOL_ID: 'us-east-2:580ed80a-f742-42d8-bcc6-fd96c851fc1c',
    },
    apiGateway: {
      REGION: 'us-east-2',
      URL: 'https://z8ntogtd38.execute-api.us-east-2.amazonaws.com/prod',
    },
  },
}
