import { assign, Machine } from 'xstate'
import {
  fetchLogIn,
  checkIsAuth,
  fetchSignUp,
  handleConfirmationSubmit,
} from './actions'

import { authState } from './authState'

export const createMachine = () => {
  const state = JSON.parse(localStorage.getItem('app-state'))
  const socialState = state ? state.social : 'displaySignIn'

  const notAuthState = {
    initial: 'signIn',
    onDone: { target: 'auth' },
    states: {
      signIn: {
        initial: socialState,
        onDone: { target: 'final' },
        on: {
          SWITCH_SIGNUP: { target: 'signUp' },
        },
        states: {
          displaySignIn: {
            on: {
              SIGNIN_SUBMIT: [
                {
                  target: 'signingIn',
                  cond: (ctx, event) =>
                    event.data.login !== '' && event.data.password !== '',
                },
                {
                  target: 'errorSignIn',
                },
              ],
            },
          },
          signingIn: {
            invoke: {
              id: 'signIn',
              src: async (ctx, event) => fetchLogIn(ctx, event),
              onDone: {
                actions: assign({
                  userInfo: (ctx, event) => event.data,
                }),
                target: 'successSignIn',
              },
              onError: {
                target: 'errorSignIn',
                actions: assign({
                  msg: (ctx, event) => event.data.message,
                }),
              },
            },
          },
          errorSignIn: {
            on: {
              SIGNIN_SUBMIT: [
                {
                  target: 'signingIn',
                  cond: (ctx, event) =>
                    event.data.login !== '' && event.data.password !== '',
                },
                {
                  target: 'errorSignIn',
                },
              ],
            },
          },
          successSignIn: {
            type: 'final',
          },
        },
      },
      signUp: {
        onDone: { target: 'final' },
        on: {
          SWITCH_SIGNIN: { target: 'signIn' },
        },
        initial: 'inputUserData',
        states: {
          inputUserData: {
            onDone: { target: 'inputConfirmationCode' },
            initial: 'displaySignUp',
            states: {
              displaySignUp: {
                on: {
                  SIGNUP_SUBMIT: [
                    {
                      target: 'signingUp',
                      cond: (ctx, event) =>
                        event.data.login !== '' && event.data.password !== '',
                    },
                    {
                      target: 'errorSignUp',
                    },
                  ],
                },
              },
              signingUp: {
                invoke: {
                  id: 'signUp',
                  src: async (ctx, event) => fetchSignUp(ctx, event),
                  onDone: {
                    target: 'successRequestSignUp',
                    actions: assign({
                      userInfo: (ctx, event) => event.data,
                    }),
                  },
                  onError: {
                    target: 'errorSignUp',
                    actions: assign({
                      msg: (ctx, event) =>
                        event.data.message.message || event.data.message,
                    }),
                  },
                },
              },
              errorSignUp: {
                on: {
                  SIGNUP_SUBMIT: [
                    {
                      target: 'signingUp',
                      cond: (ctx, event) =>
                        event.data.login !== '' && event.data.password !== '',
                    },
                    {
                      target: 'errorSignUp',
                    },
                  ],
                },
              },
              successRequestSignUp: {
                type: 'final',
              },
            },
          },
          inputConfirmationCode: {
            initial: 'displayConfirmationCode',
            onDone: { target: 'final' },
            states: {
              displayConfirmationCode: {
                on: {
                  CONFIRM_CODE_SUBMIT: [
                    {
                      target: 'sendingConfirmationCode',
                      cond: (ctx, event) =>
                        event.data.login !== '' && event.data.password !== '',
                    },
                    {
                      target: 'errorConfirmationCode',
                    },
                  ],
                },
              },
              sendingConfirmationCode: {
                invoke: {
                  id: 'checkingConfirmationCode',
                  src: async (ctx, event) =>
                    handleConfirmationSubmit(ctx, event),
                  onDone: {
                    target: 'successSignUp',
                    actions: assign({
                      userInfo: (ctx, event) => event.data,
                    }),
                  },
                },
              },
              errorConfirmationCode: {},
              successSignUp: { type: 'final' },
            },
          },
          final: { type: 'final' },
        },
      },
      final: { type: 'final' },
    },
  }

  const stateMachine = Machine({
    initial: 'checkingAuth',
    context: {},
    states: {
      checkingAuth: {
        invoke: {
          id: 'checkIsAuth',
          src: async (ctx, event) => checkIsAuth(ctx, event),
          onDone: {
            target: 'auth',
            actions: assign({
              userInfo: (ctx, event) => event.data,
            }),
          },
          onError: {
            target: 'notAuth',
          },
        },
      },
      auth: { ...authState },
      notAuth: { ...notAuthState },
    },
  })
  return stateMachine
}
