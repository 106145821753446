import API from '@aws-amplify/api'
import { stage } from '../../config/config'

export const loadTemplates = async () => {
  const templates = await API.get(stage, '/shipping')
  return templates
}

export const addTemplate = async (context, event) => {
  await API.post(stage, '/shipping', { body: event.data })
}

export const deleteTemplate = async (context, event) => {
  const templateId = context.selectedTemplate
  await API.del(stage, `/shipping/${templateId}`)
}

export const changeTemplate = async (context, event) => {
  const { id, cost, name, origin } = event.data
  await API.put(stage, `/shipping/${id}`, {
    body: { cost, name, origin },
  })
}
