import API from '@aws-amplify/api'
import Auth from '@aws-amplify/auth'
import { JSEncrypt } from 'jsencrypt'
import { stage } from '../../config/config'

const publicKeyForTokens = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAxnUtq9bVACXNP7fqi+jQ
t9g4X0TjE+VWqDzXY8e14mN4gNN7pPiJDwY7EY7h0WITSzU7g57n/n5pVeMM0f6D
0v4gunmunVz6gwdvME9iBDIEj19kvLKE9GjzLA5VLbKvBYiirwcf6LV1ogrdLAEM
PuO1NGGPouj32zxJ/GUsCROIFCswBM3gKF0pRImS1KNsPH88T0YZK1iKrq/bzmLO
0uBVAjmR2B3BWEs1foNq9Wy6b2MdHjhMJkr9HoraWnHBHM3eTsj1e0VJFG2Ln5FN
mkeP7D3XUtjQc7gxnd9Wn0wWK3s3LIzlHwzo1bbfCHUT/r/tFELB2mIwrwg1FVlM
CwIDAQAB
-----END PUBLIC KEY-----`

export const saveTokens = async (ctx, event) => {
  const { id, token } = event.data
  const marketplace = 'US'

  const crypt = new JSEncrypt()
  crypt.setKey(publicKeyForTokens)

  const encriptedSellerId = crypt.encrypt(id)
  const encriptedToken = crypt.encrypt(token)

  try {
    await API.post(stage, '/mws/tokens', {
      body: {
        seller_id: encriptedSellerId,
        auth_token: encriptedToken,
        marketplace,
      },
    })
  } catch (e) {
    console.log(e)
  }
}

export const checkUserTokens = async () => {
  const { tokens } = await API.get(stage, '/mws/tokens')
  if (tokens.length > 0) {
    throw new Error('Already saved')
  }
  return null
}
