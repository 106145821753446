import Amplify, { ConsoleLogger } from '@aws-amplify/core'
import Auth from '@aws-amplify/auth'

import { config, stage } from './config'

console.log('S: ', stage)

const getToken = async () => {
  const token = await Auth.currentSession()
  return token.idToken.jwtToken
}

// Amplify.Logger.LOG_LEVEL = 'DEBUG'

export default () => {
  Amplify.configure({
    Auth: {
      mandatorySignIn: true,
      region: config[stage].cognito.REGION,
      userPoolId: config[stage].cognito.USER_POOL_ID,
      identityPoolId: config[stage].cognito.IDENTITY_POOL_ID,
      userPoolWebClientId: config[stage].cognito.APP_CLIENT_ID,
    },
    API: {
      endpoints: [
        {
          name: 'dev',
          endpoint: config[stage].apiGateway.URL,
          region: config[stage].apiGateway.REGION,
          custom_header: async () => ({
            Authorization: await getToken(),
          }),
        },
        {
          name: 'prod',
          endpoint: config[stage].apiGateway.URL,
          region: config[stage].apiGateway.REGION,
          custom_header: async () => ({
            Authorization: await getToken(),
          }),
        },
      ],
    },
  })
}
