import API from '@aws-amplify/api'
import { stage } from '../../config/config'

export const getUserSettings = async () => {
  try {
    const result = await API.get(stage, '/profile')
    return result
  } catch (err) {
    console.error(err)
  }
}

export const saveUserSettings = async (ctx, event) => {
  const { settings } = event.data
  try {
    await API.put(stage, '/profile', {
      body: { ...settings },
    })
  } catch (err) {
    console.error(err)
  }
}
